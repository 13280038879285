<template>
  <v-container fluid class="pa-0">
    <v-row class="scroll-container ove">
      <v-col cols="12" class="scroll-area app-bar-white">
        <MainBannerSection/>
      </v-col>
      <v-col cols="12" class="scroll-area container reveal fade-right" v-if="$vuetify.breakpoint.mdAndUp">
        <MainStoreCarouselSection/>
      </v-col>
      <v-col cols="12" class="scroll-area container reveal fade-top" v-if="$vuetify.breakpoint.smAndDown">
        <MainStoreInfoSection/>
      </v-col>
      <v-col cols="12" class="scroll-area container reveal fade-left" v-if="$vuetify.breakpoint.mdAndUp">
        <MainCarouselSection/>
      </v-col>
      
      <v-col cols="12" class="scroll-area container reveal fade-left" v-if="$vuetify.breakpoint.smAndDown">
        <MainManagementSection/>
      </v-col>
      <v-col cols="12" class="scroll-area container reveal fade-right" v-if="$vuetify.breakpoint.smAndDown">
        <MainPartnerSection/>
      </v-col>
      <v-col cols="12" class="scroll-area container reveal fade-left" v-if="$vuetify.breakpoint.smAndDown">
        <MainFranchiseSection/>
      </v-col>

      <v-col cols="12" class="scroll-area container reveal fade-right">
        <MainMapSection/>
      </v-col>
      <!-- <v-col cols="12" class="scroll-area container reveal fade-top">
        <MainPremiumSection/>
      </v-col>
      <v-col cols="12" class="scroll-area container reveal fade-left">
        <MainChaSection/>
      </v-col>
      <v-col cols="12" class="scroll-area container reveal fade-bottom">
        <MainKitchenSection/>
      </v-col>
      <v-col cols="12" class="scroll-area container">
        <MainMapingSection/>
      </v-col> -->
      <!-- <v-col cols="12" class="scroll-area container reveal fade-right">
        <MainEndSection/>
      </v-col>  -->
      <v-col cols="12" class="scroll-area container reveal fade-left">
        <MainMembershipSection/>
      </v-col>
      <v-col cols="12" class="scroll-area box container reveal fade-top">
        <Footer/>
      </v-col>
      <!-- <Scroller/> -->
    </v-row>
  </v-container>
</template>



<script>
function reveal() {
  var reveals = document.querySelectorAll(".reveal");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 150;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}

window.addEventListener("scroll", reveal);

import { mapActions } from 'vuex'
import MainBannerSection from './components/MainBannerSection.vue'
import MainStoreCarouselSection from './components/MainStoreCarouselSection.vue'
import MainStoreInfoSection from './components/MainStoreInfoSection.vue'
import MainCarouselSection from './components/MainCarouselSection.vue'
import MainManagementSection from './components/MainManagementSection.vue'
import MainPartnerSection from './components/MainPartnerSection.vue'
import MainMapSection from './components/MainMapSection.vue'
import MainFranchiseSection from './components/MainFranchiseSection.vue'
import MainMembershipSection from './components/MainMembershipSection.vue'
import Footer from '@/views/home/footer/Footer'


export default {  
  components: {
    MainBannerSection,
    MainStoreCarouselSection,
    MainStoreInfoSection,
    MainCarouselSection,
    MainManagementSection,
    MainPartnerSection,
    MainMapSection,
    MainFranchiseSection,
    MainMembershipSection,
    Footer,
  
  },
  
  
  
  name: 'Main',
  mounted() {
    // const options = { threshold: [1] }
    // const observer = new IntersectionObserver(entries => {
    //   entries.forEach(entry => {
    //     if(entry.isIntersecting) {
    //       this.updateAppBar(!entry.target.classList.contains('app-bar-white'))
    //     }
    //   });
    // }, options);
    // document.querySelectorAll('.scroll-area').forEach(el => observer.observe(el));
  },
  methods: {
    ...mapActions(['updateAppBarColor',]),
    updateAppBar(value) {
      this.updateAppBarColor(value)
    },
  }
}
</script>
<style lang="scss" scoped>
.scroll-container::-webkit-scrollbar {
  display: none;
}
.scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: 20px ; /* Firefox */
  overflow-y: scroll !important;
  scroll-snap-type: y mandatory !important;
  
  
  height: 100vh !important;   /* 이걸고치시오 */
  
  
  margin: 0 !important;
  padding: 0;
.scroll-area {   /* 스크롤 범위 */
  scroll-snap-align: start !important;
  width: 100% !important;
  height: 100vh !important;
  background-size: cover !important;
  position: relative !important;
  margin: 0 !important;
  padding: 0 !important;
}
  .last {
    z-index: 999;
  }
 
}
.ove {
  margin: 0 !important;
  overflow-x: hidden;
}
.box {
  height: 40vh !important;
  margin: 0 !important;
  padding: 0 !important;
}


// @media (max-width: 900px) {
//   section h1 {
//     font-size: 2rem;
//     text-align: center;
//   }
//   section .text-container {
//     flex-direction: column;
//   }
// }

// .reveal {
//   position: relative;
//   opacity: 0;
// }

// .reveal.active {
//   opacity: 1;
// }
// .active.fade-bottom {
//   animation: fade-bottom 1s ease-in;
// }
// .active.fade-left {
//   animation: fade-left 1s ease-in;
// }
// .active.fade-right {
//   animation: fade-right 1s ease-in;
// }
// .active.fade-top {
//   animation: fade-top 1s ease-in;
// }
// @keyframes fade-bottom {
//   0% {
//     transform: translateY(50px);
//     opacity: 0;
//   }
//   100% {
//     transform: translateY(0);
//     opacity: 1;
//   }
// }
// @keyframes fade-left {
//   0% {
//     transform: translateX(-100px);
//     opacity: 0;
//   }
//   100% {
//     transform: translateX(0);
//     opacity: 1;
//   }
// }

// @keyframes fade-right {
//   0% {
//     transform: translateX(100px);
//     opacity: 0;
//   }
//   100% {
//     transform: translateX(0);
//     opacity: 1;
//   }
// }

// @keyframes fade-top {
//   0% {
//     transform: translateY(-50px);
//     opacity: 0;
//   }
//   100% {
//     transform: translateY(0);
//     opacity: 1;
//   }
// }
</style>