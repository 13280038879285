<template>
  <v-container fluid fill-height class="bg pa-0 ma-0" justify-center :class="{'mobile': $vuetify.breakpoint.smAndDown}">
    <v-row justify-center align-center class="row">
      <v-col cols="8" md="8" justify-center class="pa-0 ma-auto">
        <Kinesis-Container>
          <Kinesis-Element>
            <div class="text-h6 text-md-h4 font-weight-black text-center lightly-dark--text  pt-6">
              <!-- <vue-typer 
                :text='["요식업 장사의 필요한","모든 순간"]'
                :repeat='Infinity'
                :shuffle='false'
                initial-action='typing'
                :pre-type-delay='70'
                :type-delay='70'
                :pre-erase-delay='2000'
                :erase-delay='250'
                erase-style='clear'
                :erase-on-complete='false'
                caret-animation='blink'
              ></vue-typer> -->
              <div class="black--text mt-16">요식업 장사의 필요한</div>
              <div class="black--text mt-md-4"
                :class="{'mt-1' : $vuetify.breakpoint.smAndDown}"
              >모든 순간,</div>
              <div class="ma-auto mt-md-4 align-center"
                :class="{'mt-1' : $vuetify.breakpoint.smAndDown}"
              >
                <v-col class="pa-0 d-flex justify-center align-center">
                  <div>'</div>
                  <v-img 
                    src="@/assets/logo/logo_text.png" 
                    :max-height="$vuetify.breakpoint.mdAndUp ? 32 : 21" 
                    :max-width="$vuetify.breakpoint.mdAndUp ? 269.7161 : 177.0012"
                    :class="{'mx-auto': $vuetify.breakpoint.smAndDown}" 
                  >
                  </v-img>
                  <div class="black--text">'이</div>
                </v-col>
              </div>
              <div class="black--text mt-md-4"
                :class="{'mt-1' : $vuetify.breakpoint.smAndDown}"
              >여러분을 
                <span class="typer-bg pl-4 pr-4">
                  <vue-typer 
                    :text='["서포트","응원","함께"]'
                    :repeat='Infinity'
                    :shuffle='false'
                    initial-action='typing'
                    :pre-type-delay='70'
                    :type-delay='70'
                    :pre-erase-delay='2000'
                    :erase-delay='250'
                    erase-style='clear'
                    :erase-on-complete='false'
                    caret-animation='expand'
                  ></vue-typer>
                </span>
              합니다.</div>
            </div>
          </Kinesis-Element>
        </Kinesis-Container>
        <v-col
          class="pa-0 pt-6 mt-4 d-flex justify-space-between ml-auto mr-auto picto"
          :class="{'mb-picto' : $vuetify.breakpoint.smAndDown}"
        >
          <div class="align-center cursor">
            <v-img
              src="@/assets/images/iv_entering.png"
              :max-width="$vuetify.breakpoint.mdAndUp ? 150 : 80" 
              :max-height="$vuetify.breakpoint.mdAndUp ? 150 : 80"
              @click="navigateTo('/entering')"
              class="cursor"
            >
            </v-img>
          </div>
          <div class="align-center cursor"> 
            <v-img
              src="@/assets/images/iv_info.png"
              :max-width="$vuetify.breakpoint.mdAndUp ? 150 : 80" 
              :max-height="$vuetify.breakpoint.mdAndUp ? 150 : 80"
              @click="navigateTo('/store-infomation')"
              class="cursor"
            >
            </v-img> 
          </div>
          <div class="align-center cursor">
            <v-img
              src="@/assets/images/iv_franchise.png"
              :max-width="$vuetify.breakpoint.mdAndUp ? 150 : 80" 
              :max-height="$vuetify.breakpoint.mdAndUp ? 150 : 80"
              @click="navigateTo('/franchise')"
              class="cursor"
            >
            </v-img>
          </div>
        </v-col>
      </v-col>
    </v-row>
  </v-container> 
</template>

<script >
import { KinesisContainer, KinesisElement} from 'vue-kinesis'
export default {
  name: 'MainBannerSection',
  components: {
   KinesisContainer, KinesisElement 
  },
  methods: {
    navigateTo(link) { 
      if (link.includes('http')) {
        window.open(link)
      } else {
        this.$router.push(link)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.typer-bg {
  background-color: rgb(255, 255, 255 , 0.8);
  border-bottom: 2px solid rgb(181, 195, 248);
}
.vue-typer {
  font-family: 'Jua', sans-serif !important;
  .custom.char {
  color: #D4D4BD !important;
  background-color: #1E1E1E !important;
    &.selected {
      background-color: #264F78 !important;
    }
  }
  .custom.caret {
    width: 10px;
    background-color: #3F51B5 !important;
  }
}
.bg {
  background-image: url('../../../../assets/images/arc_overlay.png');
  background-size: cover;
  background-position: center; 
  

@import "https://fonts.googleapis.com/css2?family=Jua&display=swap";

  .jua {
    font-family: 'Jua', sans-serif;
  }
  .row {
    position: relative;
    z-index: 1;
    max-width: 1000px !important;
  }
}
.bg.mobile {
  background-image: url('../../../../assets/back-m.png');
}
.pic {
  width: 100px;
}
.picto {
  width: 600px;
}
.mb-picto {
  width: 300px;
}
.typical-wrapper::after {
  content: "|";
  animation: blink 1s infinite step-start;
}
@keyframes blink {
  50% { opacity: 0; }
}
.pointer {
  cursor: pointer !important;
}
</style>