<template>
  <v-container fill-height fluid justify-center>
    <v-row justify-center align-center class="contents-box">
      <v-col cols="12" md="6" 
        :order="$vuetify.breakpoint.mdAndUp ? 1 : 2"
        class="d-flex align-center" 
        :class="{'justify-center': $vuetify.breakpoint.mdAndDown, 'justify-start': $vuetify.breakpoint.lgAndUp}"
      >
        <div 
          class="lightly-dark--text text-h6 text-md-h4 font-weight-black"
          :class="{'text-center': $vuetify.breakpoint.smAndDown}"
        >
          <div>1000여개의</div>
          <div class="lightly-dark--text mt-md-4 align-center"
            :class="{'mt-1' : $vuetify.breakpoint.smAndDown}"
          >협력업체가</div>
          <div class="lightly-dark--text mt-md-4 align-center"
            :class="{'mt-1' : $vuetify.breakpoint.smAndDown}"
          >할인된 가격으로</div>
          <div class="lightly-dark--text mt-md-4 align-center"
            :class="{'mt-1' : $vuetify.breakpoint.smAndDown}"
          >서비스를 제공해요.</div>
          <div class="lightly-dark--text text-subtitle-2 text-md-h6 font-weight-black mt-md-6"
            :class="{'mt-2' : $vuetify.breakpoint.smAndDown}"
          >창업의 가장 문제인</div>
          <div class="lightly-dark--text text-subtitle-2 text-md-h6 font-weight-black mt-md-1 align-center">
            <v-col class="lightly-dark--text pa-0 d-flex align-center justify-start"
              :class="{'justify-center' : $vuetify.breakpoint.smAndDown}"
            >
              <div>고정비를 '</div>
              <v-img 
                src="@/assets/logo/logo_text.png"
                :max-height="$vuetify.breakpoint.mdAndUp ? 18 : 12" 
                :max-width="$vuetify.breakpoint.mdAndUp ? 151.7153 : 101.1435"
              >
              </v-img>  
              <div>'이</div> 
            </v-col>
          </div>
          <div class="lightly-dark--text text-subtitle-2 text-md-h6 font-weight-black mt-md-1">낮춰 안정적인 창업을 도와요.</div>
          <div class="mt-md-6 justify-start">
            <v-btn
              :width="$vuetify.breakpoint.mdAndUp ? 160 : 120"
              :height="$vuetify.breakpoint.mdAndUp ? 48 : 36"
              outlined
              color="primary-red"
              elevation="0"
              class="round_box text-h7 font-weight-bold  ma-auto"
              :class="{'mt-4' : $vuetify.breakpoint.smAndDown}"
              @click="() => $router.push('/entering')"
            >입점신청
            </v-btn>
            <v-btn
              :width="$vuetify.breakpoint.mdAndUp ? 160 : 120"
              :height="$vuetify.breakpoint.mdAndUp ? 48 : 36"
              outlined
              color="primary-blue"
              elevation="0"
              class="round_box text-h7 font-weight-bold  ma-auto ml-4"
              :class="{'mt-4' : $vuetify.breakpoint.smAndDown}"
              @click="navigateTo('/franchise')"
            >프랜차이즈 문의
            </v-btn>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="6" :order="$vuetify.breakpoint.mdAndUp ? 2 : 1">
        <v-img 
          src="@/assets/images/partner.png" 
          :max-width="$vuetify.breakpoint.smAndDown ? '260px' : '780px'"
          max-height="600" 
          :class="{'mx-auto': $vuetify.breakpoint.smAndDown}" 
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'MainPartnerSection',
  methods: {
    navigateTo(link) {
      if (link.includes('http')) {
        window.open(link)
      } else {
        this.$router.push(link)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "https://fonts.googleapis.com/css2?family=Jua&display=swap";

.jua {
  font-family: 'Jua', sans-serif;
}
.contents-box {
  max-width: 1000px !important;
}
.round_box {
  border-radius: 8px;
}
</style>