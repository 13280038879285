<template>
  <v-container fluid fill-height class="bg pa-0 d-flex justify-center">
    <v-row 
      justify-center 
      align-center 
      :class="{'pa-0 contents-box d-flex justify-start' : $vuetify.breakpoint.mdAndUp
      , 'contents-box-m d-flex justify-center' : $vuetify.breakpoint.smAndDown}"
    >
      <div
        :class="{'text-size-m d-flex flex-column ml-auto mr-auto' : $vuetify.breakpoint.smAndDown
        , 'text-size d-flex ' : $vuetify.breakpoint.mdAndUp}"  
      >
        <div 
          class="text-h6 text-md-h3 font-weight-black text-start"
        >
          <div 
            class="pa-0"
          >
            <div
              :class="{'text-center f-size text-h4 font-weight-black' : $vuetify.breakpoint.smAndDown
              , 'lightly-dark-2--text text-md-h4 font-weight-bold' : $vuetify.breakpoint.mdAndUp}"
            >아크키친 제휴현황
            </div>
            <div
              :class="{'text-center premium-gold--text f-size text-h4 font-weight-black' : $vuetify.breakpoint.smAndDown
              , 'premium-gold--text text-md-h4 font-weight-bold' : $vuetify.breakpoint.mdAndUp}"
            >MEMBERSHIP
            </div>
          </div>  
        </div>
        <v-img
          v-if="$vuetify.breakpoint.mdAndUp"
          src="@/assets/images/font-img.png"
          :max-height="$vuetify.breakpoint.mdAndUp ? 26 : 12" 
          :max-width="$vuetify.breakpoint.mdAndUp ? 380 : 230.9091"
          class="d-flex align-center ml-auto mt-auto mb-auto justify-end"
        ></v-img>  
      </div>
      <!-- <v-col cols="12" class="d-flex justify-space-between pa-0 logo-mt align-center">
        <v-img
          src="@/assets/logo/jeonju.jpg"
          :max-height="$vuetify.breakpoint.mdAndUp ? 60 : 18" 
          :max-width="$vuetify.breakpoint.mdAndUp ? 177.75 : 50"
        ></v-img>
        <v-img
          src="@/assets/logo/iksan.png"
          :max-height="$vuetify.breakpoint.mdAndUp ? 60 : 18" 
          :max-width="$vuetify.breakpoint.mdAndUp ? 194.6224 : 50"
        ></v-img>
        <v-img
          src="@/assets/logo/orange.png"
          :max-height="$vuetify.breakpoint.mdAndUp ? 60 : 18" 
          :max-width="$vuetify.breakpoint.mdAndUp ? 147 : 50"
        ></v-img>
        <v-img
          src="@/assets/logo/kt.png"
          :max-height="$vuetify.breakpoint.mdAndUp ? 60 : 18" 
          :max-width="$vuetify.breakpoint.mdAndUp ? 73.5245 : 50"
        ></v-img>
        <v-img
          src="@/assets/logo/cesco.png"
          :max-height="$vuetify.breakpoint.mdAndUp ? 60 : 18" 
          :max-width="$vuetify.breakpoint.mdAndUp ? 106.5 : 50"
        ></v-img>
      </v-col>
      <v-col cols="12" class="d-flex justify-space-between pa-0 logo-mt2 align-center">
        <v-img
          src="@/assets/logo/barogo.png"
          :max-height="$vuetify.breakpoint.mdAndUp ? 30 : 18" 
          :max-width="$vuetify.breakpoint.mdAndUp ? 201.1554 : 50"
        ></v-img>
        <v-img
          src="@/assets/logo/kb.png"
          :max-height="$vuetify.breakpoint.mdAndUp ? 32 : 18" 
          :max-width="$vuetify.breakpoint.mdAndUp ? 194.2948 : 50"
        ></v-img>
        <v-img
          src="@/assets/logo/easy.png"
          :max-height="$vuetify.breakpoint.mdAndUp ? 50 : 18" 
          :max-width="$vuetify.breakpoint.mdAndUp ? 214.1434 : 50"
        ></v-img>
        <v-img
          src="@/assets/logo/jeonjugoob.png"
          :max-height="$vuetify.breakpoint.mdAndUp ? 60 : 18" 
          :max-width="$vuetify.breakpoint.mdAndUp ? 60 : 50"
        ></v-img>
        <v-img
          src="@/assets/logo/star.png"
          :max-height="$vuetify.breakpoint.mdAndUp ? 60 : 18" 
          :max-width="$vuetify.breakpoint.mdAndUp ? 60 : 50"
        ></v-img>
      </v-col> -->
      <div v-if="$vuetify.breakpoint.mdAndUp">
        <MemberLogoPc/>
      </div>
      <div v-if="$vuetify.breakpoint.smAndDown">
        <MemberShipLogoMo/>
      </div>
    </v-row>
  </v-container> 
</template>

<script>
import MemberLogoPc from '../components/MemberShipLogo/MemberLogoPc'
import MemberShipLogoMo from '../components/MemberShipLogo/MemberShipLogoMo.vue'

export default {
  components: {
    MemberLogoPc,
    MemberShipLogoMo,
  }
}
</script>

<style lang="scss" scoped>
.bg {
  background-color: white;
  // background-size: cover;
  // .overlay {
  //   width: 100%;
  //   height: 100vh;
  //   position: absolute;
  //   z-index: 0;
  //   top: 0;
  //   left: 0;
  // }
  // .row {
  //   position: relative;
  //   z-index: 1;
  // }
.contents-box {
  max-width: 1000px !important;
}
  .v-btn {
    margin-top: 50px;
  }
}
.typer-bg {
  background-color: rgba(228, 234, 253, 0.4);
  border-bottom: 4px solid rgb(216, 225, 254);
}
.logo-mt {
  margin-top: 114px;
}
.logo-mt2 {
  margin-top: 60px;
}
.text-size-m {
  width: 300px !important;
}
.text-size {
  width: 1000px !important;
}
.f-size {
  width: 300px !important;
}
.contents-box-m {
  width: 300px !important;
}
</style>