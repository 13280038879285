<template>
  <v-container fill-height fluid justify-center class="bg">
    <div class="box-size d-0flex justify-center ma-auto">
      <section class='galeria'>
        <input type="radio" id="uno" value="1" name="tractor" checked='checked' />    
        <input type="radio" id="dole" value="2" name="tractor" />      
        <input type="radio" id="tele" value="3" name="tractor" />
        <article class='card una'>
          <label for='dole' class='entypo-left-bold otra'></label>
          <label for='tele' class='entypo-right-bold otra'></label>
          <label for='uno' class='entypo-arrows-ccw afin'></label> 
          <div class="card-box ma-auto align-center justify-center d-flex flex-column">
            <div class="text-box ml-auto mr-auto">
              <div class="text-h3 font-weight-black lightly-dark-2--text">바로 여기!</div>
              <div class="text-h3 font-weight-black mt-2 lightly-dark-2--text">'<span class="primary-red--text">아크키친</span> <span class="primary-blue--text"> 공유주방</span>'에</div>
              <div class="text-h3 font-weight-black mt-2 lightly-dark-2--text">모두~다 있다</div>
              <div class="text-h5 mt-6 lightly-dark-2--text">창업부터 운영관리까지 모든것</div>
              <div class="text-h5 mt-1 lightly-dark-2--text">(공간,인테리어,배달앱,배달,포스기,주방기구,컨설팅 등)</div>
            </div>
            <div class="btn-box ml-auto mr-auto mt-6">
              <v-btn
                :width="$vuetify.breakpoint.mdAndUp ? 160 : 120"
                :height="$vuetify.breakpoint.mdAndUp ? 48 : 36"
                outlined
                color="primary-red"
                elevation="0"
                class="round_box_r text-h7 font-weight-bold  ma-auto"
                :class="{'mt-4' : $vuetify.breakpoint.smAndDown}"
                @click="() => $router.push('/entering')"
              >입점문의</v-btn>
              <v-btn
                :width="$vuetify.breakpoint.mdAndUp ? 160 : 120"
                :height="$vuetify.breakpoint.mdAndUp ? 48 : 36"
                outlined
                color="primary-blue"
                elevation="0"
                class="round_box_b text-h7 font-weight-bold  ma-auto ml-4"
                :class="{'mt-4' : $vuetify.breakpoint.smAndDown}"
                @click="navigateTo('http://timmy63.cafe24.com/contactus')"
              >프랜차이즈문의</v-btn>
            </div>  
          </div>  
        </article>
        
        <article class='card dos'>
          <label for='tele' class='entypo-left-bold otra'></label>
          <label for='uno' class='entypo-right-bold otra'></label>
          <label for='dole' class='entypo-arrows-ccw afin'></label> 
          <div class="card-box ma-auto align-center justify-center d-flex flex-column">
            <div class="text-box ml-auto mr-auto">
              <div class="text-h3 font-weight-black lightly-dark-2--text">1,000개의</div>
              <div class="text-h3 font-weight-black mt-2 lightly-dark-2--text">협력업체가</div>
              <div class="text-h3 font-weight-black mt-2 lightly-dark-2--text">할인된 가격으로</div>
              <div class="text-h3 font-weight-black mt-2 lightly-dark-2--text">서비스를 제공해요</div>
              <div class="text-h5 mt-6 lightly-dark-2--text">창업의 가장 문제인</div>
              <div class="text-h5 mt-1 lightly-dark-2--text">고정비를 '<span class="primary-red--text font-weight-black">아크키친</span> <span class="primary-blue--text font-weight-black"> 공유주방</span>'이</div>
              <div class="text-h5 mt-1 lightly-dark-2--text">낮춰 안정적인 창업을 도와요</div>
            </div>
            <div class="btn-box ml-auto mr-auto mt-6">
              <v-btn
                :width="$vuetify.breakpoint.mdAndUp ? 160 : 120"
                :height="$vuetify.breakpoint.mdAndUp ? 48 : 36"
                outlined
                color="primary-red"
                elevation="0"
                class="round_box_r text-h7 font-weight-bold  ma-auto"
                :class="{'mt-4' : $vuetify.breakpoint.smAndDown}"
                @click="() => $router.push('/entering')"
              >입점문의</v-btn>
              <v-btn
                :width="$vuetify.breakpoint.mdAndUp ? 160 : 120"
                :height="$vuetify.breakpoint.mdAndUp ? 48 : 36"
                outlined
                color="primary-blue"
                elevation="0"
                class="round_box_b text-h7 font-weight-bold  ma-auto ml-4"
                :class="{'mt-4' : $vuetify.breakpoint.smAndDown}"
                @click="navigateTo('http://timmy63.cafe24.com/contactus')"
              >프랜차이즈문의</v-btn>
            </div>  
          </div> 
        </article>
        
        <article class='card tres'>
          <label for='uno' class='entypo-left-bold otra'></label>
          <label for='dole' class='entypo-right-bold otra'></label>
          <label for='tele' class='entypo-arrows-ccw afin'></label> 
          <div class="card-box ma-auto align-center justify-center d-flex flex-column">
            <div class="text-box ml-auto mr-auto">
              <div class="text-h3 font-weight-black lightly-dark-2--text">'<span class="primary-red--text">아크키친</span> <span class="primary-blue--text"> 공유주방</span>'</div>
              <div class="text-h3 font-weight-black mt-2 lightly-dark-2--text">프랜차이즈로</div>
              <div class="text-h3 font-weight-black mt-2 lightly-dark-2--text">우리 건물을 활성화시켜요</div>
              <div class="text-h5 mt-6 lightly-dark-2--text">자동 결제 시스템</div>
              <div class="text-h5 mt-1 lightly-dark-2--text">1,000여개 협력사 혜택</div>
              <div class="text-h5 mt-1 lightly-dark-2--text">공동 협력사 혜택 ・ 마케팅 ・ 관리</div>
            </div>
            <div class="btn-box ml-auto mr-auto mt-6">
              <v-btn
                :width="$vuetify.breakpoint.mdAndUp ? 160 : 120"
                :height="$vuetify.breakpoint.mdAndUp ? 48 : 36"
                outlined
                color="primary-red"
                elevation="0"
                class="round_box_r text-h7 font-weight-bold  ma-auto"
                :class="{'mt-4' : $vuetify.breakpoint.smAndDown}"
                @click="() => $router.push('/entering')"
              >입점문의</v-btn>
              <v-btn
                :width="$vuetify.breakpoint.mdAndUp ? 160 : 120"
                :height="$vuetify.breakpoint.mdAndUp ? 48 : 36"
                outlined
                color="primary-blue"
                elevation="0"
                class="round_box_b text-h7 font-weight-bold  ma-auto ml-4"
                :class="{'mt-4' : $vuetify.breakpoint.smAndDown}"
                @click="navigateTo('/franchise')"
              >프랜차이즈문의</v-btn>
            </div>  
          </div> 
        </article>
      </section>
    </div>
  </v-container>
</template>

<script>
// import MainManagementBox from './MainCarouselSheet/MainManagementBox.vue'
// import MainPartnersBox from './MainCarouselSheet/MainPartnersBox.vue'
// import MainFranchiseBox from './MainCarouselSheet/MainFranchiseBox.vue'

export default {
  components: {
    // MainManagementBox,
    // MainPartnersBox,
    // MainFranchiseBox,
  },
  name: 'MainMapSection',
  methods: {
    navigateTo(link) {
      if (link.includes('http')) {
        window.open(link)
      } else {
        this.$router.push(link)
      }
    }
  }
};


</script>

<style lang="scss" scoped>


*, *:after, *:before {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
* {margin:0;padding:0;border: 0 none;position: relative;}
[class*="entypo-"]:before {font-family: 'entypo', sans-serif; display: inline-block; margin-right: .5rem;}

html, body  {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}
body {display: flex;}
body > div {
  margin: auto;
}

.galeria {
  width: 1200px;
  transform-style: preserve-3d;
}
.galeria:before {
  content: '';
  display: inline-block;
  width: 0;
}
.card {
  background-size: cover;
  background-position: 0 0;
  width: 1200px;
  height: 600px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 0 2rem rgba(0,0,0,.5);
  border-radius: 25px;
}
.una {
  background: url("../../../../assets/images/all-have.png");
  background-size: cover;
}
.dos {
  background: url("../../../../assets/images/service-give.png");
  background-size: cover;
}
.tres {
  background: url("../../../../assets/images/shop-effect.png");
  background-size: cover;
}
h2 {
  color: #fff;
  font-family: lato;
  text-align: center;
  font-weight: 300;
  line-height: 8vh;
  background: #FF7361;
  box-shadow: 0 0 5px rgba(0,0,0,.7);
}
h2:before {
  position: absolute;
  left: 1rem;
  font-weight: 300;
  color: #00262F;
}
.card p {
  text-align: center;
  color: #fff;
  line-height: 1.3;
  margin-top: 3vh;
}
h3 {
  font-size: 5vw;
  font-weight: 700;
  margin: 1vh 0 1vh;
}
#uno:checked ~ .una .footer,
#dole:checked ~ .dos .footer,
#tele:checked ~ .tres .footer {
  background: #0D757D;
  color: #15BFCC;
}
li {
  float: left;
  width: 33.33%;
  text-align: center;
}
li:before {  
  line-height: 6vh;
}
input {visibility: hidden;}
label {
  background: #1510e7;
  position: absolute;
  top: 0; bottom: 0;
  margin: auto 0;
  line-height: 12vh; 
  text-align: right;
  height: 8vh;
  width: 1vw;
  padding: 0 .3vw;
  cursor: pointer;
  opacity: .2;
  transition: .3s linear;
  border-radius: 8px;
  margin-left: 8px;
  margin-right: 8px;
}
.otra + .otra,
#uno:checked ~ .tres .afin,
#dole:checked ~ .una .afin,
#tele:checked ~ .dos .afin {
  right: 0;
  text-align: left;
}
#uno:checked ~ .una .otra,
#dole:checked ~ .dos .otra,
#tele:checked ~ .tres .otra {
  background:  #1510e7;
  display: block;
}
#uno:checked ~ .una .afin,
#dole:checked ~ .dos .afin,
#tele:checked ~ .tres .afin,
:not(:checked) ~ .otra
{display: none;}

.card:hover label {
  animation: pulso .5s infinite alternate;
}
.card:hover label + label {
  animation-delay: .5s;
}
.card:hover label:hover {
  animation: none;
  opacity: .8;
  width: 3.5vw;
}

#uno:checked ~ .dos,
#dole:checked ~ .tres,
#tele:checked ~ .una {
  animation: 1s fuera-izq;
  animation-fill-mode: forwards;
  transform-origin: center left;
  z-index: 2;
}
#uno:checked ~ .tres,
#dole:checked ~ .una,
#tele:checked ~ .dos {
  animation: 1.5s fuera-dch;
  animation-fill-mode: forwards;
  transform-origin: center right;
  z-index: 2;
}
#uno:checked ~ .una,
#dole:checked ~ .dos,
#tele:checked ~ .tres {
  animation: 2s central;
  animation-fill-mode: forwards;
  transform-origin: center center;
  z-index:3;
}

@keyframes fuera-izq {
  0% {transform: scale(1) translatex(0%);} 
  70% {transform: scale(.9) translatex(-100%);}
  100% {transform: scale(.9) translatex(-7%);}
}
@keyframes fuera-dch {
  0% {transform: scale(1) translatex(0%);}
  70% {transform: scale(.9) translatex(100%);}
  100% {transform: scale(.9) translatex(7%);}
}
@keyframes central {
  0% {transform: scale(.8);z-index: 1;}
  80% {transform: scale(.5);z-index: 3;}
  100% {transform: scale(1);z-index: 3;}
}
@keyframes pulso {
  50% {opacity: .7;}
}
 .bg {
  background-color: white;
  width: 100%;
  height: 100%;
}
.box-size {
  width: 1200px;
  height: 600px;
}
.round_box_r {
  border-radius: 8px;
  z-index: 999 !important;
  border: thin solid #F90050;
}
.round_box_b {
  border-radius: 8px;
  z-index: 999 !important;
  border: thin solid #0443F9;
}
.btn-box {
  width: 1000px;
}
.text-box {
  width: 1000px;
}
.card-box {
  width: 1000px;
  height: 600px;
}
</style>