<template>
  <v-container fill-height fluid justify-center class="bg" :class="{'bg-pc' : $vuetify.breakpoint.mdAndUp}">
    <v-row justify-center align-center class="contents-box">
      <v-col cols="12" md="6" 
        :order="$vuetify.breakpoint.mdAndUp ? 1 : 2"
        class="d-flex align-center" 
        :class="{'justify-center': $vuetify.breakpoint.mdAndDown, 'justify-start': $vuetify.breakpoint.lgAndUp}"
      >
        <div 
          class="lightly-dark--text text-h6 text-md-h4 font-weight-black"
          :class="{'text-center': $vuetify.breakpoint.smAndDown}"
        >
          
          <div>우리지역 요식업창업</div>
          <div class="lightly-dark--text mt-md-4"
            :class="{'mt-1' : $vuetify.breakpoint.smAndDown}"
          >최적의 장소를</div>
          <div class="lightly-dark--text mt-md-4 align-center"
            :class="{'mt-1' : $vuetify.breakpoint.smAndDown}"
          >
            <v-col class="lightly-dark--text pa-0 d-flex align-center justify-start"
              :class="{'justify-center' : $vuetify.breakpoint.smAndDown}"
            >
              <div><strong class="primary-red--text">요식업</strong><strong class="primary-blue--text"> 최적의장소</strong>에</div>
            </v-col>
            <div class="lightly-dark--text mt-md-4"
            :class="{'mt-1' : $vuetify.breakpoint.smAndDown}"
          >최적의 공간∙서비스를 제공해요.</div>
          </div>
          <div class="mt-md-6 justify-start">
            <v-btn
              :width="$vuetify.breakpoint.mdAndUp ? 160 : 120"
              :height="$vuetify.breakpoint.mdAndUp ? 48 : 36"
              outlined
              color="primary-red"
              elevation="0"
              class="round_box text-h7 font-weight-bold  ma-auto"
              :class="{'mt-4' : $vuetify.breakpoint.smAndDown}"
              @click="() => $router.push('/entering')"
            >입점신청
            </v-btn>
            <v-btn
              :width="$vuetify.breakpoint.mdAndUp ? 160 : 120"
              :height="$vuetify.breakpoint.mdAndUp ? 48 : 36"
              outlined
              color="primary-blue"
              elevation="0"
              class="round_box text-h7 font-weight-bold  ma-auto ml-4"
              :class="{'mt-4' : $vuetify.breakpoint.smAndDown}"
              @click="navigateTo('/franchise')"
            >프랜차이즈 문의
            </v-btn>
          </div>
        </div>
      </v-col>
      
      <v-col 
        cols="12" 
        md="6" :order="$vuetify.breakpoint.mdAndUp ? 2 : 1"
        class="pl-6"
        v-if="$vuetify.breakpoint.mdAndUp"
      > 
        <v-hover v-slot="{ hover }">
          <a href="https://map.naver.com/v5/entry/place/1121396385?placePath=%2Fhome">
            <v-img
              src="../../../../assets/images/map-phone.png"
              :max-width="$vuetify.breakpoint.smAndDown ? '600px' : '600px'"
              max-height="600"
              :class="{'mx-auto': $vuetify.breakpoint.smAndDown}"
            >
              <v-expand-transition>
                <div
                  v-if="hover"
                  class="d-flex transition-fast-in-fast-out white darken-2 v-card--reveal text-body-1 black--text align-center pb-4 pt-4"
                  style="height: 100%;"
                >
                  매장 위치 자세히보기
                </div>
              </v-expand-transition>
            </v-img>
          </a>
        </v-hover>
      </v-col>
      
      <v-col 
        cols="12" 
        md="6" :order="$vuetify.breakpoint.mdAndUp ? 2 : 1"
        class="pl-6"
        v-if="$vuetify.breakpoint.smAndDown"
      > 
        <v-hover v-slot="{ hover }">
          <a href="https://map.naver.com/v5/entry/place/1121396385?placePath=%2Fhome">
            <v-img
              src="@/assets/images/map.png"
              :max-width="$vuetify.breakpoint.smAndDown ? '234px' : '800px'"
              max-height="600"
              :class="{'mx-auto': $vuetify.breakpoint.smAndDown}"
            >
              <v-expand-transition>
                <div
                  v-if="hover"
                  class="d-flex transition-fast-in-fast-out white darken-2 v-card--reveal text-body-1 black--text align-start pb-4 pt-4"
                  style="height: 100%;"
                >
                  매장 위치 자세히보기
                </div>
              </v-expand-transition>
            </v-img>
          </a>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'MainMapSection',
  methods: {
    navigateTo(link) {
      if (link.includes('http')) {
        window.open(link)
      } else {
        this.$router.push(link)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "https://fonts.googleapis.com/css2?family=Jua&display=swap";

.jua {
  font-family: 'Jua', sans-serif;
}
.contents-box {
  max-width: 1000px !important;
}
.round_box {
  border-radius: 8px;
  background-color: white;
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .8;
  position: absolute;
  width: 100%;
  border-radius: 300px !important; 
}
.bg {
  // background-color: #f5f9ff !important;
  // background: url("../../../../assets/images/map-bg.png");
  background-size: cover !important;
}
.bg-pc {
  background-color: #f5f9ff !important;
}
</style>