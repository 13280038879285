<template>
  <v-container fill-height fluid justify-center>
    <v-row justify-center align-center class="contents-box">
      <v-col cols="12" md="6" 
        :order="$vuetify.breakpoint.mdAndUp ? 1 : 2"
        class="d-flex align-center" 
        :class="{'justify-center': $vuetify.breakpoint.mdAndDown, 'justify-start': $vuetify.breakpoint.lgAndUp}"
      >
        <div 
          class="lightly-dark--text text-h6 text-md-h4 font-weight-black"
          :class="{'text-center': $vuetify.breakpoint.smAndDown}"
        >  
          <div>바로 여기!</div>
          <div class="lightly-dark--text mt-md-4 align-center">
            <v-col class="lightly-dark--text pa-0 d-flex align-center justify-start"
              :class="{'justify-center' : $vuetify.breakpoint.smAndDown}"
            >
              <div>'</div>
              <v-img 
                src="@/assets/logo/logo_text.png" 
                :max-height="$vuetify.breakpoint.mdAndUp ? 32 : 21" 
                :max-width="$vuetify.breakpoint.mdAndUp ? 269.7161 : 177.0012"
              >
              </v-img>  
              <div>'에</div>
            </v-col>
          </div>
          <div class="lightly-dark--text mt-md-4"
            :class="{'mt-1' : $vuetify.breakpoint.smAndDown}"
          >모두~다 있다</div>
          <div class="lightly-dark--text text-subtitle-2 text-md-h6 font-weight-black mt-md-6"
            :class="{'mt-2' : $vuetify.breakpoint.smAndDown}"
          >창업부터 운영관리까지 모든것</div>
          <div class="lightly-dark--text text-subtitle-2 text-md-subtitle-1 font-weight-black">(공간,인테리어,배달앱,배달,포스기,주방기구,컨설팅 등)</div>
          <div class="mt-md-6 justify-start">
            <v-btn
              :width="$vuetify.breakpoint.mdAndUp ? 160 : 120"
              :height="$vuetify.breakpoint.mdAndUp ? 36 : 36"
              outlined
              color="primary-red"
              elevation="0"
              class="round_box text-h7 font-weight-bold  ma-auto"
              :class="{'mt-4' : $vuetify.breakpoint.smAndDown}"
              @click="() => $router.push('/entering')"
            >입점신청
            </v-btn>
            <v-btn
              :width="$vuetify.breakpoint.mdAndUp ? 160 : 120"
              :height="$vuetify.breakpoint.mdAndUp ? 36 : 36"
              outlined
              color="primary-blue"
              elevation="0"
              class="round_box text-h7 font-weight-bold  ma-auto ml-4"
              :class="{'mt-4' : $vuetify.breakpoint.smAndDown}"
              @click="navigateTo('/franchise')"
            >프랜차이즈 문의
            </v-btn>
          </div>
        </div> 
      </v-col>
      <v-col cols="12" md="6" :order="$vuetify.breakpoint.mdAndUp ? 2 : 1">
        <v-img 
          src="@/assets/images/manage.png" 
          :max-width="$vuetify.breakpoint.smAndDown ? '245px' : '800px'"
          max-height="640" 
          :class="{'mx-auto': $vuetify.breakpoint.smAndDown}"
        />   
      </v-col>   
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'MainManagementSection',
  methods: {
    navigateTo(link) {
      if (link.includes('http')) {
        window.open(link)
      } else {
        this.$router.push(link)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "https://fonts.googleapis.com/css2?family=Jua&display=swap";

.jua {
  font-family: 'Jua', sans-serif;
}
.contents-box {
  max-width: 1000px !important;
}
.round_box {
  border-radius: 8px;
}
</style>