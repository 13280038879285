<template>
  <v-container fill-height fluid justify-center class="bg">
    <div class="carousel">
      <div class="text-box text-center d-flex justify-cneter ml-auto mr-auto flex-column">
        <div class="text-h6 text-md-h4 font-weight-black ml-auto mr-auto white--text">
          <v-col 
              lass="pa-0 d-flex justify-start "
          >'아크키친 공유주방'은
          </v-col>
          <div class="text-caption text-md-h6 font-weight-black"
          :class="{'mt-1 text-start' : $vuetify.breakpoint.smAndDown}">
            전라북도 최초 최대규모, 최적 요식업 전 분야 1등급(상권정보시스템)<br/>상권에서
            여러 요식업자분들의 성공적이며 안정적인 창업을 돕습니다.
          </div>
        </div>
        <div class="btn-box ml-auto mr-auto mt-3">
              <v-btn
                :width="$vuetify.breakpoint.mdAndUp ? 160 : 120"
                :height="$vuetify.breakpoint.mdAndUp ? 48 : 36"
                outlined
                color="primary-red"
                elevation="0"
                class="round_box text-h7 font-weight-bold  ma-auto"
                :class="{'mt-4' : $vuetify.breakpoint.smAndDown}"
                @click="() => $router.push('/entering')"
              >입점문의</v-btn>
              <v-btn
                :width="$vuetify.breakpoint.mdAndUp ? 160 : 120"
                :height="$vuetify.breakpoint.mdAndUp ? 48 : 36"
                outlined
                color="primary-blue"
                elevation="0"
                class="round_box text-h7 font-weight-bold  ma-auto ml-8"
                :class="{'mt-4' : $vuetify.breakpoint.smAndDown}"
                @click="navigateTo('/franchise')"
              >프랜차이즈문의</v-btn>
            </div>
      </div>
      <div class="carousel-inner mt-md-10 d-flex justify-center ml-auto mr-auto">
          <input class="carousel-open" type="radio" id="carousel-1" name="carousel" aria-hidden="true" hidden="" checked="checked">
          <div class="carousel-item">
              <v-img 
                height="360"
                width="302.4"
                src="../../../../assets/images/A-Long.png"
                @click="navigateTo('/store-infomation')"   
              >
              </v-img>
          </div>
          <input class="carousel-open" type="radio" id="carousel-2" name="carousel" aria-hidden="true" hidden="">
          <div class="carousel-item">
              <v-img 
                height="360"
                width="302.4"
                src="../../../../assets/images/B-Long.png"
                @click="navigateTo('/store-infomation')"
              ></v-img>
          </div>
          
          <label for="carousel-2" class="carousel-control next control-1 text-end btn-B-R" height="360" width="302.4"></label>
          <label for="carousel-1" class="carousel-control prev control-2 text-start btn-B-L" height="360" width="302.4"></label>
   
          <label for="carousel-2" class="carousel-control prev control-1 text-start btn-A-L" height="360" width="302.4"></label>
          <label for="carousel-1" class="carousel-control next control-2 text-end btn-A-R" height="360" width="302.4"></label>
      </div>
  </div>
  </v-container>
</template>

<script>
export default {
  methods: {
    navigateTo(link) {
      if (link.includes('http')) {
        window.open(link)
      } else {
        this.$router.push(link)
      }
    }
  }
}  

</script>

<style lang="scss" scoped>
html, body {
    margin: 0px;
    padding: 0px;
    background: url("http://digital.bnint.com/filelib/s9/photos/white_wood_4500x3000_lo_res.jpg");
}

.bg {
  background-size: cover !important;
  background: url("../../../../assets/images/blue-wall.png") ;
}
.carousel {
  position: relative;

 
}

.carousel-inner {
    position: relative;
    overflow: hidden;
    width: 1440px;
    //  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.64);
}

.carousel-open:checked + .carousel-item {
    position: static;
    opacity: 100;
}

.carousel-item {
    position: absolute;
    opacity: 0;
    width: 302.4px !important;
    height: 360px !important;
    -webkit-transition: opacity 0.6s ease-out;
    transition: opacity 0.6s ease-out;
}

.carousel-item img {
    display: block;
    width: 100%;
}

.carousel-control {
    border-radius: 50%;
    color: #fff;
    cursor: pointer;
    display: none;
    font-size: 40px;
    height: 40px;
    line-height: 35px;
    position: absolute;
    top: 18vh;
    -webkit-transform: translate(0, -50%);
    cursor: pointer;
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    text-align: center;
    width: 40px;
    z-index: 10;
}

.carousel-control.prev {
    width: 1000px;
    margin-left: 26vh;
}

.carousel-control.next {
    width: 1000px;
    margin-right: 26vh;
}

.carousel-control:hover {
    color: #aaaaaa;
}

#carousel-1:checked ~ .control-1,
#carousel-2:checked ~ .control-2 {
    display: block;
}

.carousel-indicators {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 2%;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 10;
}

.carousel-indicators li {
    display: inline-block;
    margin: 0 5px;
}

.carousel-bullet {
    color: red;
    cursor: pointer;
    display: none;
    font-size: 35px;
}

.carousel-bullet:hover {
    color: #aaaaaa;
}

#carousel-1:checked ~ .control-1 ~ .carousel-indicators li:nth-child(1) .carousel-bullet,
#carousel-2:checked ~ .control-2 ~ .carousel-indicators li:nth-child(2) .carousel-bullet {
    color: #428bca;
}

#title {
    width: 100%;
    position: absolute;
    padding: 0px;
    margin: 0px auto;
    text-align: center;
    font-size: 27px;
    color: rgba(255, 255, 255, 1);
    z-index: 9999;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.33), -1px 0px 2px rgba(255, 255, 255, 0);
}
.text-box {
  width: 1000px !important;
  margin-top: 100px !important;
}
.round_box {
  border-radius: 8px;
  background-color: white !important;
}
.btn-B-R {
  background: url("../../../../assets/images/B-Right-btn.png");
  width: 302.4px !important;
  height: 360px !important;
  background-size: cover !important;
  border-radius: 12px !important;
  top: 180px !important;
  right: -60px !important;
}
.btn-A-R {
  background: url("../../../../assets/images/A-Right-btn.png");
  width: 302.4px !important;
  height: 360px !important;
  background-size: cover !important;
  border-radius: 12px !important;
  top: 180px !important;
  right: -60px !important;
}
.btn-B-L {
  background: url("../../../../assets/images/B-Left-btn.png");
  width: 302.4px !important;
  height: 360px !important;
  background-size: cover !important;
  border-radius: 12px !important;
  top: 180px !important;
  left: -60px !important;
}
.btn-A-L {
  background: url("../../../../assets/images/A-Left-btn.png");
  width: 302.4px !important;
  height: 360px !important;
  background-size: cover !important;
  border-radius: 12px !important;
  top: 180px !important;
  left: -60px !important;
}
</style>